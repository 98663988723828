import React, { useState, useEffect, useContext } from 'react';
import { graphql }                                from 'gatsby';
import Sticky                                     from 'react-stickynode';
import handleViewport                             from 'react-in-viewport';

// import constants
import { API_URL, BACKEND_URL } from '../constants';

// import context
import { GlobalStateContext } from '../context/GlobalContextProvider';

// import helpers
import { useWindowSize } from '../helpers';

// import styles
import { SingleArticle } from '../styles/BlogSingle';

// import components
import Seo          from '../components/common/Seo';
import PageMeta     from '../components/elements/PageMeta';
import Trending     from '../components/elements/Trending';
import SponsoredAd  from '../components/elements/Ad/SponsoredAd';
import PostComments from '../components/elements/PostComments';
import ShareAction  from '../components/elements/ShareBox/ShareAction';

/**
 * Define the viewport anchor
 *
 * @param props | object
 */
const TheEndOfArticle = ( props ) => {
  const { forwardedRef } = props;

  return (
    <div className="left-column__end" ref={ forwardedRef } />
  );
};

/**
 * Use the react-in-viewport plugin
 */
const ShowTheEndOfArticle = handleViewport( TheEndOfArticle );

export const query = graphql`
  query($id: String!, $lang: String) {
    posts(id: { eq: $id }, lang: { eq: $lang }) {
      postId
      data {
        lang
        author {
          description_de
          description_en
          image
          image_alt
          name
        }
        category
        comments {
          author {
            gravatar
            username
          }
          date
          id
          text
        }
        date
        global_texts {
          answer_text
          comments_text_multiple
          comments_text_single
          give_answer_text
          minutes_text
          post_comment_text
          read_more_text
          reads_text
          register_button_text
          write_your_opinion_logged_in_text
          write_your_opinion_not_logged_in_text
          write_your_opinion_text
          comment_error
          comment_error_length
          comment_success
          answer_error_length
          share_text
          load_more
        }
        image_featured
        image_featured_alt
        image_main
        image_main_alt
        level
        long_description {
          h1 {
            title
          }
          image_sections {
            image_alt
            image_desktop
            image_mobile
            image_tablet
            position
          }
          paragraphs {
            position
            text
          }
        }
        reading_time
        seo_meta_description
        seo_site_title
        short_description
        slug
        title
        og_image
      }
    }
  }
`;

export default function ArticlePage ( { data } ) {
  // get props data
  /*const {
    data: {
      posts: {
        data: {
          title, image_main, image_main_alt, total_comments, reading_time, date, category,
          author: { authorName, authorAvatar, authorAvatarAlt },
          long_description: { h1: { title: postTitle }, paragraphs, image_sections },
          global_texts: {
            comments_text_single, comments_text_multiple, minutes_text, write_your_opinion_text,
            write_your_opinion_not_logged_in_text, register_button_text
          },
          comments
        }
      }
    }
  } = props;*/

  const {
    posts: {
      postId,
      data: {
        title, image_main, image_main_alt, reading_time, date, category, image_featured, og_image,
        author: { name: authorName, image: authorAvatar, image_alt: authorAvatarAlt, description_en, description_de },
        //long_description: { h1: { title: postTitle }, paragraphs, image_sections },
        long_description, seo_site_title, seo_meta_description, slug, lang,
        global_texts: {
          comments_text_single, comments_text_multiple, minutes_text, write_your_opinion_text, post_comment_text,
          write_your_opinion_not_logged_in_text, register_button_text, answer_text, write_your_opinion_logged_in_text,
          give_answer_text, comment_error, comment_error_length, comment_success, answer_error_length, load_more, share_text
        }
      }
    }
  } = data;

  // get the component context
  const state = useContext( GlobalStateContext );
  const { defaultLang } = state;

  // define the default component state
  const [theEndOfArticle, setTheEndOfArticle]   = useState( false );
  const [postsComments, setPostsComments]       = useState( [] );

  // define the component custom hooks
  const { width } = useWindowSize();

  /**
   * Update post data
   */
  useEffect( () => {
    fetch( API_URL + 'blog_new/posts-sort-by-date-ascending/?lang=' + defaultLang )
      .then( response => response.json() )
      .then( data => {
        setPostsComments( data.filter_results )
      } );
  }, [defaultLang] );

  /**
   * Join the all content types together
   */
  const { paragraphs, image_sections } = long_description;

  const getParagraphs = paragraphs.map( paragraph => {
    const { position, text } = paragraph;

    return { type: 'text', position, text };
  });

  const getImages = image_sections.map( image => {
    const { position, image_desktop, image_tablet, image_mobile, image_alt } = image;

    return {
      type: 'image',
      position,
      desktopImg: image_desktop,
      tabletImg: image_tablet,
      mobileImg: image_mobile,
      alt: image_alt
    }
  });

  let SectionPositions = [...getParagraphs, ...getImages];

  // sort content types by position
  const sortedPositions = SectionPositions.sort( ( a, b ) => a.position - b.position );

  // define the right comment text
  let totalComments = 0;

  postsComments.forEach( post => {
    if ( post.id === postId ) {
      totalComments = post.total_comments;
    }
  });

  const commentsText  = totalComments === 1 ? comments_text_single : comments_text_multiple;
  const commentsCount = `${ totalComments } ${ commentsText }`;
  const postUrl = lang === 'de' ? `/${ slug }` : `/en/${ slug }`;

  const commentsData = {
    postId,
    commentsTextSingle: comments_text_single,
    commentsTextMultiple: comments_text_multiple,
    opinionText: write_your_opinion_text,
    opinionLoggedText: write_your_opinion_logged_in_text,
    opinionNotLoggedText: write_your_opinion_not_logged_in_text,
    postCommentText: post_comment_text,
    registerBtnText: register_button_text,
    answerText: answer_text,
    giveAnswerText: give_answer_text,
    commentError: comment_error,
    commentErrorLen: comment_error_length,
    commentSuccess: comment_success,
    answerErrorLen: answer_error_length,
    loadMore: load_more
  };

  const handleShowTheEndOfArticle = ( flag ) => {
    setTheEndOfArticle( flag );
  };

  const handleFreeze = () => {
    const test = document.getElementsByClassName( 'post__side__floating' )[0];

    if ( theEndOfArticle ) {
      test.classList.add( 'slide-to-right' );
    } else {
      test.classList.remove( 'slide-to-right' );
    }
  };

  // define image meta tags link
  const ogImgPath = `${ BACKEND_URL }${ og_image }`;

  return (
    <>
      <Seo title={ seo_site_title } description={ seo_meta_description } lang={ defaultLang } imageLink={ ogImgPath } />
      <PageMeta type="single" text={ title } />
      <div className="container">
        <SingleArticle>
          <div className="top-container">
            <h1 className="article-title">{ title }</h1>
            <img className="main-img"
                 src={ `${ BACKEND_URL }${ width > 991 ? image_main : image_featured }` }
                 alt={ image_main_alt } />
            <div className="meta">
              <div className="meta__author">
                <img className="meta__author__avatar"
                     src={ BACKEND_URL + authorAvatar }
                     alt={ authorAvatarAlt } />
                <div className="meta__author__info">
                  <span className="meta__author__name">{ authorName }</span>
                  <p className="meta__author__bio">{ defaultLang === 'de' ? description_de : description_en }</p>
                </div>
              </div>

              <div className="meta__info">
                <span className="meta__info__item">{ commentsCount }</span>
                <span className="meta__info__item">{ `${ reading_time } ${ minutes_text }` }</span>
                <span className="meta__info__item">{ date }</span>
              </div>
            </div>
          </div>

          <div className="columns-container">
            <div className="left-column">
              <span className="post__category">{ category }</span>
              <div className="post__title__container">
                <h2 className="post__title">{ long_description.h1 !== null ? long_description.h1.title : title }</h2>
                <ShareAction wrapperClass="post__actions"
                             staticClass="post__actions__share"
                             activeClass="post__actions__share post__actions__share--is-active"
                             shareBoxPosition={ width > 991 ? 'center' : 'right' }
                             shareIconClass="post__actions__share__icon"
                             postTitle={ title }
                             postUrl={ postUrl } />
              </div>

              <div className="post__content">
                { sortedPositions.map( position => {
                  const {
                    type, text, position: positionIndex,
                    desktopImg, tabletImg, mobileImg, alt
                  } = position;

                  // show text content
                  if ( type === 'text' ) {
                    return <p className="post__content__item"
                              key={ positionIndex }
                              dangerouslySetInnerHTML={ { __html: text } } />;

                    // show fullwidth image content
                  } else if ( type === 'image' ) {
                    if ( width <= 550 ) {
                      return <img className="post__content__img"
                                  key={ positionIndex }
                                  src={ BACKEND_URL + mobileImg }
                                  alt={ alt } />
                    } else if ( width > 550 && width <= 991 ) {
                      return <img className="post__content__img"
                                  key={ positionIndex }
                                  src={ BACKEND_URL + tabletImg }
                                  alt={ alt } />
                    } else if ( width > 991 ) {
                      return <img className="post__content__img"
                                  key={ positionIndex }
                                  src={ BACKEND_URL + desktopImg }
                                  alt={ alt } />
                    } else return false;
                  } else return false;
                } ) }
              </div>

              <div className="post__actions__bottom">
                <div className="post__actions__bottom__body">
                  <div className="post__actions__bottom__item">
                    <span className="post__actions__bottom__item__text">{ share_text }</span>

                    <ShareAction wrapperClass="post__actions__bottom__item__wrapper"
                                 staticClass="post__actions__bottom__item__icon"
                                 activeClass="post__actions__bottom__item__icon post__actions__bottom__item__icon--is-active"
                                 shareBoxPosition={ width > 480 ? 'center' : 'right' }
                                 shareIconClass="post__actions__bottom__item__icon__item"
                                 postTitle={ title }
                                 postUrl={ postUrl } />
                  </div>
                </div>
              </div>
              <PostComments data={ commentsData } />
              <ShowTheEndOfArticle onEnterViewport={ () => handleShowTheEndOfArticle( true ) }
                                   onLeaveViewport={ () => handleShowTheEndOfArticle( false ) } />
            </div>

            { width > 991 ?
              <div className="right-column">
                <Trending className="post__side__trending"
                          desktop={ 2 }
                          horizontalTablet={ 2 }
                          verticalTablet={ 2 }
                          mobile={ 2 } />
                <Sticky innerClass="post__side__floating"
                        top={ 200 }
                        shouldFreeze={ () => handleFreeze() }>
                  <SponsoredAd additionalClass="post__side__ad" />
                </Sticky>
              </div>
            : false }
          </div>
        </SingleArticle>
      </div>
    </>
  )
}