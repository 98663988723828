import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const ReplyIcon = memo( ( { className } ) => {
  return (
    <svg className={ className }
         xmlns="http://www.w3.org/2000/svg"
         width="18"
         height="15.75"
         viewBox="0 0 18 15.75">
      <path d="M.292,7.8,6.48,2.456a.844.844,0,0,1,1.4.639V5.909C13.522,5.973,18,7.105,18,12.457a7.187,7.187,0,0,1-2.93,5.419.627.627,0,0,1-.987-.655c1.594-5.1-.756-6.452-6.208-6.53v3.091a.844.844,0,0,1-1.4.639L.292,9.076a.844.844,0,0,1,0-1.277Z"
            transform="translate(0 -2.25)" />
    </svg>
  )
});

ReplyIcon.defaultProps = {
  className: ''
};

ReplyIcon.propTypes = {
  className: PropTypes.string
};

export default ReplyIcon;