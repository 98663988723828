import styled from 'styled-components';

export const SingleArticle = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .top-container {
    --header-height: 100px;
    --categories-height: 100px;
    --breadcrumbs-height: 60px;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    max-width: 1070px;
    height: calc(100vh - var(--header-height) - var(--categories-height) - var(--breadcrumbs-height));
    margin-bottom: 70px;
    padding-bottom: 20px;
  }

  .article-title {
    margin: 0 0 15px 0;
    text-align: center;
    font-size: 48px;
    font-weight: 800;
    line-height: 54px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
  }
  
  .main-img {
    display: block;
    width: 1070px;
    max-width: 1070px;
    height: 406px;
    margin-bottom: 25px;
  }
  
  .meta {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #707070;
    padding-top: 20px;
    
    &__author {
      display: flex;
      align-items: flex-start;
      
      &__avatar {
        --dimensions: 90px;
        
        width: var(--dimensions);
        height: var(--dimensions);
        margin-right: 55px;
        border-radius: 50%;
      }
      
      &__name {
        display: block;
        margin-bottom: 10px;
        font-weight: 700;
        letter-spacing: 1px;
        color: var(--theme-dark-gray);
      }
      
      &__bio {
        max-width: 410px;
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 1.25px;
        color: var(--theme-dark-gray);
      }
    }
    
    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      
      &__item {
        display: flex;
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 1.25px;
        color: var(--theme-dark-gray);
      }
    }
  }
  
  .columns-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  
  .left-column {
    position: relative;
    width: 740px;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    
    &__end {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 1px;
    }
  }
  
  .right-column {
    width: 515px;
  }
  
  .post {
    &__category {
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 25px;
      letter-spacing: 1.2px;
      color: var(--theme-blue);
    }
    
    &__title {
      margin: 0 0 30px 0;
      font-size: 36px;
      line-height: 41px;
      font-weight: 700;
      letter-spacing: 1.2px;
    
      &__container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
    }
    
    &__actions {
      position: relative;
      margin-left: 50px;
      
      &__share {
        --dimensions: 40px;
        
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--dimensions);
        height: var(--dimensions);
        border-radius: 50%;
        background-color: var(--white);
        transition: .3s all ease-in-out;
      
        &:hover {
          cursor: pointer;
        }
        
        &--is-active {
          background-color: var(--theme-dark-purple);
          
          svg {
            path {
              fill: var(--white);
            }
          }
        }
      
        &__icon {
          transition: .3s all ease-in-out;
        }
      }
      
      &__bottom {
        margin-bottom: 70px;
        padding: 70px 0;
        border-top: 2px solid var(--theme-gray);
        border-bottom: 2px solid var(--theme-gray);
        
        &__body {
          display: flex;
          flex-direction: column;
          padding: 40px 120px 40px 70px;
          background-color: var(--theme-white);
        }
        
        &__item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          
          &__wrapper {
            position: relative;
          }
          
          &__text {
            font-size: 18px;
            font-weight: 700;
            line-height: 21px;
            letter-spacing: 1.25px;
            text-transform: uppercase;
            color: var(--theme-dark-purple);
          }
          
          &__icon {
            --dimensions: 50px;
            
            display: flex;
            align-items: center;
            justify-content: center;
            width: var(--dimensions);
            height: var(--dimensions);
            border: 2px solid var(--theme-dark-purple);
            border-radius: 50%;
            transition: .3s all ease-in-out;
            
            &:hover {
              cursor: pointer;
            }
            
            &--is-active {
              background-color: var(--theme-dark-purple);
              
              svg {
                path {
                  fill: var(--white);
                }
              }
            }
            
            &__item {
              --dimensions: 28px;
            
              width: var(--dimensions);
              height: var(--dimensions);
              transition: .3s all ease-in-out;
            }
          }
        }
      }
    }
    
    &__content {
      &__item {
        margin: 0 0 30px 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: 1.25px; 
        
        p {
          margin: 0 0 30px 0;
          font-size: 16px;
          font-weight: 400;
          line-height: 29px;
          letter-spacing: 1.25px; 
        }
        
        h2 {
          margin: 0 0 30px 0;
          font-size: 36px;
          font-weight: 700;
          line-height: 41px;
          letter-spacing: 1.2px;
        }
        
        h3 {
          margin: 0 0 17px 0;
          font-size: 28px;
          font-weight: 700;
          line-height: 32px;
          letter-spacing: 1.2px;
          color: var(--theme-dark-purple);
        }
        
        h4 {
          margin: 0 0 17px 0;
          font-size: 18px;
          font-weight: 700;
          line-height: 21px;
          letter-spacing: 1.2px;
          color: var(--theme-dark-purple);
        }
        
        h5, h6 {
          margin: 0 0 17px 0;
          font-size: 18px;
          font-weight: 600;
          line-height: 21px;
          letter-spacing: 1.2px;
          color: var(--theme-dark-purple);
        }
        
        a {
          color: var(--theme-blue);
          text-decoration: none;
          word-break: break-word;
          
          &:hover {
            text-decoration: underline;
          }
        }
      }
      
      &__img {
        display: block;
        width: 100%;
        margin-bottom: 30px;
      }
    }
    
    &__side {
      &__trending {
        margin-bottom: 60px;
      }
      
      &__ad {
        right: 0;
        opacity: 1;
        transition: .3s all ease-in-out;
      }
      
      &__floating {
        
      
        &.slide-to-right {
          & > div {
            right: -120%;
            opacity: 0;
          }
        }
      }
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/
  @media (max-width: 1440px) {
    .top-container {
      --header-height: 75px;
      --categories-height: 75px;
    
      max-width: 950px;
    }
    
    .main-img {
      width: 90%;
      height: auto;
      margin-bottom: 20px;
    }
    
    .meta {
      padding-top: 15px;
    }
    
    .right-column {
      width: 470px;
    }
  }
  
  @media (max-width: 1280px) {
    .top-container {
      max-width: 830px;
    }
    
    .main-img {
      width: 83%;
    }
    
    .meta {
      &__author {
        &__avatar {
          margin-right: 30px;
        }
        
        &__name {
          margin-bottom: 0;
        }
        
        &__bio {
          line-height: 20px;
        }
      }
    }
    
    .left-column {
      width: 630px;
    }
    
    .right-column {
      width: 410px;
    }
    
    .post {
      &__actions {
        &__bottom {
          &__body {
            padding: 40px 70px;
          }
        }
      }
    }
  }
  
  @media (max-width: 1024px) {
    .article-title {
      font-size: 30px;
      line-height: 34px;
    }
    
    .top-container {
      max-width: 760px;
    }
    
    .left-column {
      width: 600px;
    }
    
    .right-column {
      width: 320px;
    }
  }
  
  @media (max-width: 991px) {
    .top-container {
      --header-height: 60px;
      --categories-height: 70px;
      --breadcrumbs-height: 40px;
    
      max-width: 100%;
    }
    
    .left-column {
      width: 100%;
    }
  
    .article-title {
      margin: 15px 0;
      font-size: 40px;
      line-height: 45px;
    }
    
    .main-img {
      width: 60%;
    }
    
    .meta {
      &__author {
        &__bio {
          max-width: 350px;
        }
      }
    }
    
    .post {
      &__title {
        font-size: 32px;
        line-height: 34px;
      }
    
      &__content {
        &__item {
          h2 {
            font-size: 32px;
            line-height: 34px;
          }
          
          h3 {
            font-size: 24px;
            line-height: 26px;
          }
          
          h4 {
            font-size: 18px;
            line-height: 20px;
          }
        }
      }
    
      &__actions {
        &__bottom {
          padding: 50px 0;
        }
      }
    }
  }
  
  @media (max-width: 640px) {
    .article-title {
      line-height: 34px;
    }
    
    .meta {
      &__author {
        &__bio {
          display: none;
        }
      }
    }
    
    .post {
      &__title {
        font-size: 32px;
        font-weight: 800;
        line-height: 35px;
      }
      
      &__actions {
        display: none;
        
        &__bottom {
          padding: 30px 0;
          
          &__body {
            padding: 35px 30px;
          }
          
          &__item {
            &__text {
              width: calc(100% - 60px);
            }
          }
        }
      }
      
      &__content {
        &__item {
          h2 {
            font-size: 32px;
            font-weight: 800;
            line-height: 35px;
          }
        }
      }
    }
  }
  
  @media (max-width: 480px) {
    .article-title {
      font-size: 32px;
      line-height: 36px;
    }
    
    .meta {
      &__author {
        flex-direction: column-reverse;
        
        &__avatar {
          --dimensions: 70px;
          
          margin-right: 0;
        }
        
        &__name {
          margin-bottom: 10px;
        }
      }
    }
  
    .post {
      &__title {
        font-size: 26px;
        line-height: 28px;
      }
    
      &__content {
        &__item {
          h2 {
            font-size: 26px;
            line-height: 28px;
          }
          
          h3 {
            font-size: 22px;
            line-height: 24px;
          }
        }
      }
      
      &__actions {
        &__bottom {
          &__item {
            .share-box {
              left: -145px;
            }
          }
        }
      }
    }
  }
  
  @media (max-width: 390px) {
    .article-title {
      margin-top: 0;
    }
    
    .main-img {
      width: 55%;
    }
  }
`;