import React, { memo, useContext, useEffect, useState } from 'react';
import PropTypes                                        from 'prop-types';

// import constants
import { API_URL, AUTH_URL, BACKEND_URL, CHANGE_OVERLAY, OPEN_MOBILE_MENU, SHOW_OVERLAY } from '../../../constants';

// import context
import { GlobalDispatchContext, GlobalStateContext } from '../../../context/GlobalContextProvider';

// import helpers
import { postData, useGetCookie, useWindowSize } from '../../../helpers';

// import utils
import { getRefreshToken, setAccessToken } from '../../../utils/Auth';

// import styles
import { PostCommentsElement } from './styles';

// import components
import UserIconCircled  from '../icons/UserIconCircled';
import ReplyIcon        from '../icons/ReplyIcon';
import ArrowRegularIcon from '../icons/ArrowRegularIcon';

const PostComments = memo( ( { data } ) => {
  // get the component props
  const {
    postId, commentsTextSingle, commentsTextMultiple, opinionText, opinionLoggedText, postCommentText,
    opinionNotLoggedText, registerBtnText, answerText, giveAnswerText, commentError, commentErrorLen, commentSuccess,
    answerErrorLen, loadMore
  } = data;

  // get the component context
  const state    = useContext( GlobalStateContext );
  const dispatch = useContext( GlobalDispatchContext );
  const { isLoggedIn } = state;

  // define the component custom hooks
  const { width } = useWindowSize();
  const session   = useGetCookie( 'MMBlogSession' );

  // define the default component state
  const [isCommentErrorLength, setIsCommentErrorLength] = useState( false );
  const [isReplyErrorLength, setIsReplyErrorLength]     = useState( false );
  const [isReplyErrorOther, setIsReplyErrorOther]       = useState( false );
  const [replyErrorOtherText, setReplyErrorOtherText]   = useState( false );
  const [isCommentSuccess, setIsCommentSuccess] = useState( false );
  const [isCommentError, setIsCommentError]     = useState( false );
  const [isReplyError, setIsReplyError]         = useState( false );
  const [commentText, setCommentText]           = useState( '' );
  const [replyText, setReplyText] = useState( '' );
  const [comments, setComments]   = useState( [] );
  const [loadedCommentsCount, setLoadedCommentsCount] = useState( 5 );
  const [loadedAnswersCount, setLoadedAnswersCount] = useState( 3 );
  const [showReplyForm, setShowReplyForm] = useState({
    isShownReplyForm: false,
    replyCommentId: null
  });

  const { isShownReplyForm, replyCommentId } = showReplyForm;

  const commentLen    = comments.length;
  const commentsText  = commentLen === 1 ? commentsTextSingle : commentsTextMultiple;
  const commentsCount = `${ commentLen } ${ commentsText }`;

  /**
   * Get comments and answers
   */
  useEffect( () => {
    const url = API_URL + 'blog_new/comment-answers/' + postId;

    fetch( url )
      .then( response => response.json() )
      .then(
          data => {
            const { comment_answers } = data;

            setComments( comment_answers );
          },
          error => { console.log( error ); }
        );
  }, [postId] );

  /**
   * Processing validation comment and send data to the server
   *
   * @param e | Event
   */
  const handleSubmitComment = ( e ) => {
    e.preventDefault();

    if ( commentText !== '' ) {
      if ( commentText.length < 20 ) {
        setIsCommentErrorLength( true );
      } else {
        const data = { refresh: getRefreshToken( session ) };

        postData( AUTH_URL + 'token/refresh', 'POST', data, false )
          .then( data => {
            const response = data[0];
            const { status } = response;

            // if the user successfully refresh an Access token
            if ( status === 200 ) {
              const resJson = data[1];
              const { access } = resJson;

              setAccessToken( access, session );

              const authorizationData = {
                type: 'Bearer',
                token: access
              };

              const commentData = {
                text: commentText
              };

              postData( API_URL + 'blog_new/make-comment/' + postId, 'POST', commentData, true, authorizationData )
                .then( data => {
                  const response = data[0];
                  const { status } = response;

                  // if success
                  if ( status === 201 ) {
                    setCommentText( '' );
                    setIsCommentSuccess( true );

                    setTimeout( () => {
                      setIsCommentSuccess( false );
                    }, 5000 );

                    // update comments
                    const url = API_URL + 'blog_new/comment-answers/' + postId;

                    fetch( url )
                      .then( response => response.json() )
                      .then(
                        data => {
                          const { comment_answers } = data;

                          setComments( comment_answers );
                        },
                        error => { console.log( error ); }
                      );
                  }
                });
            }
          });
      }
    } else {
      setIsCommentError( true );
    }
  };

  /**
   * Processing when the comment field gets focus
   */
  const handleCommentFocus = () => {
    setIsCommentError( false );
    setIsCommentErrorLength( false );
  };

  /**
   * Processing when click to the Register button
   */
  const handleClickRegister = () => {
    dispatch( { type: CHANGE_OVERLAY, payload: 'register' } );

    if ( width <= 991 ) {
      dispatch( { type: OPEN_MOBILE_MENU, payload: true } );
    } else {
      dispatch( { type: SHOW_OVERLAY, payload: true } );
    }
  };

  /**
   * Processing click to the Reply button
   *
   * @param commentId | int
   */
  const handleClickReply = ( commentId ) => {
    setReplyText( '' );
    setShowReplyForm({
      isShownReplyForm: true,
      replyCommentId: commentId
    });
  };

  /**
   * Processing validation comment and send data to the server
   *
   * @param e | Event
   * @param commentId | int
   */
  const handleSubmitReply = ( e, commentId ) => {
    e.preventDefault();

    if ( replyText !== '' ) {
      if ( replyText.length < 10 ) {
        setIsReplyErrorLength( true );
      } else {
        const data = {
          refresh: getRefreshToken( session )
        };

        postData( AUTH_URL + 'token/refresh', 'POST', data, false )
          .then( data => {
            const response = data[0];
            const { status } = response;

            // if the user successfully refresh an Access token
            if ( status === 200 ) {
              const resJson = data[1];
              const { access } = resJson;

              setAccessToken( access, session );

              const authorizationData = {
                type: 'Bearer',
                token: access
              };

              const replyData = {
                text: replyText
              };

              postData( API_URL + 'blog_new/give-answer/' + commentId, 'POST', replyData, true, authorizationData )
                .then( data => {
                  const response = data[0];
                  const { status } = response;

                  // if success
                  if ( status === 201 ) {
                    setReplyText( '' );
                    setShowReplyForm({
                      isShownReplyForm: false,
                      replyCommentId: null
                    });

                    // update answers
                    const url = API_URL + 'blog_new/comment-answers/' + postId;

                    fetch( url )
                      .then( response => response.json() )
                      .then(
                        data => {
                          const { comment_answers } = data;

                          setComments( comment_answers );
                        },
                        error => { console.log( error ); }
                      );
                  }

                  // if conflict
                  if ( status === 409 ) {
                    setIsReplyErrorOther( true );
                    setReplyErrorOtherText( data[1] );
                  }
                });
            }
          });
      }
    } else {
      setIsReplyError( true );
    }
  };

  /**
   * Processing when an answer field gets focus
   */
  const handleFocusReply = () => {
    setIsReplyError( false );
    setIsReplyErrorLength( false );
    setIsReplyErrorOther( false );
  };

  /**
   * Load more comments
   */
  const handleCommentLoadMore = () => {
    setLoadedCommentsCount( c => c + 3 );
  };

  /**
   * Load more answers
   */
  const handleAnswerLoadMore = () => {
    setLoadedAnswersCount( c => c + 3 );
  };

  return (
    <PostCommentsElement className="post__comments">
      <span className="post__comments__count">{ commentsCount }</span>
      { isLoggedIn ?
        <>
          <form className="post__comments__form"
                onSubmit={ ( e ) => handleSubmitComment( e ) }>
            <h4 className="post__comments__form__title">{ opinionText }</h4>
            <textarea className={ isCommentError ? 'post__comments__form__field post__comments__form__field--is-error' : 'post__comments__form__field' }
                      value={ commentText }
                      placeholder={ opinionLoggedText }
                      onChange={ ( e ) => setCommentText( e.currentTarget.value ) }
                      onFocus={ handleCommentFocus } />
            { isCommentError ? <span className="post__comments__form__error">{ commentError }</span> : false }
            { isCommentErrorLength ? <span className="post__comments__form__error">{ commentErrorLen }</span> : false }
            <button className="post__comments__form__btn"
                    type="submit">{ postCommentText }</button>
          </form>

          { isCommentSuccess ?
            <p className="post__comments__success-msg">{ commentSuccess }</p>
          : false }
        </>
      :
        <div className="post__comments__opinion">
          <h4 className="post__comments__opinion__title">{ opinionText }</h4>
          <p className="post__comments__opinion__text">{ opinionNotLoggedText }</p>
          <button className="post__comments__opinion__btn"
                  type="button"
                  onClick={ handleClickRegister }>
            { registerBtnText }
          </button>
        </div>
      }

      { commentLen > 0 ?
        <div className="post__comments__list">
          { comments.map( ( comment, index ) => {
            const {
              id, date, text, answers,
              author: { gravatar, username }
            } = comment;

            if ( index < loadedCommentsCount ) {
              return (
                <div className="post__comments__item" key={ id }>
                  <div className="post__comments__item__top">
                    { gravatar !== null ?
                      <img className="post__comments__item__avatar"
                           src={ BACKEND_URL + gravatar }
                           alt={ username } />
                      :
                      <UserIconCircled className="post__comments__item__avatar__icon"
                                       circleColor="#D1D3D4"
                                       userColor="#fff" />
                    }

                    <div className="post__comments__item__info">
                      <span className="post__comments__item__name">{ username }</span>
                      <span className="post__comments__item__date">{ date }</span>
                    </div>
                  </div>

                  <p className="post__comments__item__text">{ text }</p>

                  { isLoggedIn ?
                    <>
                      <span className={
                        isShownReplyForm && id === replyCommentId
                          ? 'post__comments__item__reply post__comments__item__reply--is-hidden'
                          : 'post__comments__item__reply' }
                            role="presentation"
                            onClick={ () => handleClickReply( id ) }>
                      { answerText }
                        <ReplyIcon className="post__comments__item__reply__icon" />
                    </span>

                      <form className={
                        isShownReplyForm && id === replyCommentId
                          ? 'post__comments__item__reply__form'
                          : 'post__comments__item__reply__form post__comments__item__reply__form--is-hidden' }
                            onSubmit={ ( e ) => handleSubmitReply( e, id ) }>
                        <h4 className="post__comments__item__reply__form__title">{ answerText }</h4>
                        <textarea className={ isReplyError ? 'post__comments__item__reply__form__field post__comments__item__reply__form__field--is-error' : 'post__comments__item__reply__form__field' }
                                  value={ replyText }
                                  placeholder={ giveAnswerText }
                                  onChange={ ( e ) => setReplyText( e.currentTarget.value ) }
                                  onFocus={ handleFocusReply } />
                        { isReplyError ? <span className="post__comments__form__error">{ commentError }</span> : false }
                        { isReplyErrorLength ? <span className="post__comments__form__error">{ answerErrorLen }</span> : false }
                        { isReplyErrorOther ? <span className="post__comments__form__error">{ replyErrorOtherText }</span> : false }
                        <button className="post__comments__item__reply__form__btn"
                                type="submit">{ answerText }</button>
                      </form>
                    </>
                  : false }

                  { answers.length > 0 ?
                    <div className="post__comments__answers">
                      { answers.map( ( answer, index ) => {
                        const {
                          date, text,
                          author: { gravatar, username }
                        } = answer;

                        if ( index < loadedAnswersCount ) {
                          const uniqueId = '_' + Math.random().toString( 36 ).substr( 2, 9 );

                          return (
                            <div className="post__comments__answers__item" key={ uniqueId }>
                              <div className="post__comments__answers__item__top">
                                { gravatar !== null ?
                                  <img className="post__comments__answers__item__avatar"
                                       src={ BACKEND_URL + gravatar }
                                       alt={ username } />
                                  :
                                  <UserIconCircled className="post__comments__answers__item__avatar__icon"
                                                   circleColor="#D1D3D4"
                                                   userColor="#fff" />
                                }

                                <div className="post__comments__answers__info">
                                  <span className="post__comments__answers__item__name">{ username }</span>
                                  <span className="post__comments__answers__item__date">{ date }</span>
                                </div>
                              </div>

                              <p className="post__comments__answers__item__text">{ text }</p>
                            </div>
                          )
                        } else return false;
                      })}

                      { loadedAnswersCount < answers.length ?
                        <span className="post__comments__answers__load-more"
                              role="presentation"
                              onClick={ handleAnswerLoadMore }>
                          { loadMore }
                          <span className="post__comments__load-more__icon">
                            <ArrowRegularIcon className="post__comments__load-more__icon__item" color="#A0A2A3" />
                          </span>
                        </span>
                      : false }
                    </div>
                  : false }
                </div>
              )
            } else return false;
          })}

          { loadedCommentsCount < commentLen ?
            <span className="post__comments__load-more"
                  role="presentation"
                  onClick={ handleCommentLoadMore }>
              { loadMore }
              <span className="post__comments__load-more__icon">
                <ArrowRegularIcon className="post__comments__load-more__icon__item" color="#A0A2A3" />
              </span>
            </span>
          : false }
        </div>
      : false }
    </PostCommentsElement>
  );
});

PostComments.defaultProps = {
  data: {}
};

PostComments.propTypes = {
  data: PropTypes.object.isRequired
};

export default PostComments;