import styled from 'styled-components';

export const PostCommentsElement = styled.div`
  .post {
    &__comments {
      &__count {
        display: block;
        margin-bottom: 30px;
        font-size: 36px;
        font-weight: 700;
        line-height: 41px;
        letter-spacing: 1.2px;
      }
      
      &__opinion {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        padding: 40px 60px 24px 60px;
        box-shadow: 0 0 25px rgba(var(--black--rgb), 0.2);
        
        &__title {
          margin: 0 0 16px 0;
          font-size: 18px;
          font-weight: 700;
          line-height: 21px;
          text-transform: uppercase;
          letter-spacing: 1.25px;
          color: var(--theme-dark-purple);
        }
        
        &__text {
          margin: 0 0 30px 0;
          text-align: center;
          font-size: 18px;
          font-weight: 600;
          line-height: 21px;
          letter-spacing: 1.25px;
          color: var(--theme-dark-gray);
        }
        
        &__btn {
          width: 200px;
          height: 57px;
          border: 2px solid var(--theme-dark-purple);
          border-radius: 36px;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 1.25px;
          text-transform: uppercase;
          color: var(--theme-dark-purple);
          background-color: var(--white);
          
          &:hover {
            cursor: pointer;
          }
          
          &:focus {
            outline: none;
          }
        }
      }
      
      &__list {
        margin-top: 60px;
        border-radius: 8px;
        padding: 25px 35px 30px 35px;
        box-shadow: 0 0 25px rgba(var(--black--rgb),0.2);
      }
      
      &__item {
        border-top: 2px solid var(--theme-gray);
        padding-top: 25px;
        padding-bottom: 16px;
        
        &:first-child {
          border-top: none;
          padding-top: 0;
        }
        
        &:last-child {
          margin-bottom: 0;
        }
        
        &__top {
          display: flex;
          align-items: flex-start;
          width: 100%;
          margin-bottom: 10px;
        }
        
        &__avatar {
          --dimensions: 80px;
        
          width: var(--dimensions);
          height: var(--dimensions);
          border-radius: 50%;
          margin-right: 30px;
          
          &__icon {
            width: 80px;
            height: 80px;
            margin-right: 30px;
          }
        }
        
        &__info {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
        }
        
        &__name {
          margin-bottom: 2px;
          font-size: 18px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 1.2px;
        }
        
        &__date {
          font-size: 18px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 1.2px;
          color: var(--theme-dark-gray);
        }
        
        &__text {
          margin: 0 0 30px 0;
          padding-left: 110px;
          font-size: 16px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 1.25px;
        }
        
        &__reply {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 130px;
          margin: 0 0 0 auto;
          opacity: 1;
          visibility: visible;
          font-size: 18px;
          line-height: 21px;
          letter-spacing: 1.2px;
          color: var(--theme-dark-gray);
          transition: .3s all ease-in-out;
          
          &:hover {
            color: var(--theme-blue);
            cursor: pointer;
            
            svg {
              fill: var(--theme-blue);
            }
          }
          
          &--is-hidden {
            opacity: 0;
            visibility: hidden;
          }
          
          &__icon {
            margin-left: 5px;
            fill: var(--theme-dark-gray);
            transition: .3s all ease-in-out;
          }
          
          &__form {
            display: flex;
            flex-direction: column;
            max-height: 270px;
            margin-left: 110px;
            margin-bottom: 30px;
            border-radius: 8px;
            opacity: 1;
            visibility: visible;
            padding: 18px 22px;
            box-shadow: 0 0 25px rgba(var(--black--rgb), 0.2);
            
            &--is-hidden {
              max-height: 0;
              margin-bottom: 0;
              opacity: 0;
              visibility: hidden;
            }
            
            &__title {
              margin: 0 0 15px 0;
              font-size: 18px;
              font-weight: 700;
              line-height: 21px;
              text-transform: uppercase;
              letter-spacing: 1.25px;
              color: var(--theme-dark-purple);
            }
            
            &__field {
              width: 100%;
              max-width: 100%;
              min-width: 100%;
              height: 86px;
              min-height: 86px;
              margin-bottom: 10px;
              border: 1px solid transparent;
              padding: 5px 10px;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
              color: var(--theme-deep-purple);
              background-color: rgba(var(--theme-white--rgb), .6);
              transition: .15s all ease-in-out;
              
              &::placeholder {
                color: var(--theme-dark-gray);
              }
              
              &:focus {
                outline: none;
              }
              
              &--is-error {
                border-color: var(--theme-error);
              }
            }
            
            &__btn {
              width: 200px;
              height: 57px;
              border: 2px solid var(--theme-dark-purple);
              border-radius: 36px;
              font-size: 16px;
              font-weight: 500;
              line-height: 18px;
              letter-spacing: 1.25px;
              text-transform: uppercase;
              color: var(--theme-dark-purple);
              background-color: var(--white);
              
              &:hover {
                cursor: pointer;
              }
              
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
      
      &__form {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        padding: 40px 50px 25px 50px;
        box-shadow: 0 0 25px rgba(var(--black--rgb), 0.2);
        
        &__title {
          margin: 0 0 16px 0;
          font-size: 18px;
          font-weight: 700;
          line-height: 21px;
          text-transform: uppercase;
          letter-spacing: 1.25px;
          color: var(--theme-dark-purple);
        }
        
        &__field {
          width: 100%;
          max-width: 100%;
          min-width: 100%;
          height: 180px;
          min-height: 180px;
          margin-bottom: 10px;
          border: 1px solid transparent;
          padding: 16px 21px;
          font-size: 18px;
          font-weight: 400;
          line-height: 21px;
          color: var(--theme-deep-purple);
          background-color: rgba(var(--theme-white--rgb), .6);
          transition: .15s all ease-in-out;
          
          &::placeholder {
            color: var(--theme-dark-gray);
          }
          
          &:focus {
            outline: none;
          }
          
          &--is-error {
            border-color: var(--theme-error);
          }
        }
        
        &__btn {
          width: 285px;
          height: 57px;
          border: 2px solid var(--theme-dark-purple);
          border-radius: 36px;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 1.25px;
          text-transform: uppercase;
          color: var(--theme-dark-purple);
          background-color: var(--white);
          
          &:hover {
            cursor: pointer;
          }
          
          &:focus {
            outline: none;
          }
        }
        
        &__error {
          margin-bottom: 5px;
          color: var(--theme-error);
        }
      }
      
      &__answers {
        margin: 0 0 10px 110px;
        
        &__info {
          display: flex;
          flex-direction: column;
          margin-top: 2px;
        }
        
        &__item {
          margin-bottom: 20px;
          border-top: 2px solid var(--theme-gray);
          padding-top: 20px;
          
          &:last-child {
            margin-bottom: 0;
          }
        
          &__top {
            display: flex;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 5px;
          }
          
          &__avatar {
            --dimensions: 50px;
      
            width: var(--dimensions);
            height: var(--dimensions);
            border-radius: 50%;
            margin-right: 20px;
            
            &__icon {
              width: 50px;
              height: 50px;
              margin-right: 20px;
            }
          }
          
          &__name,
          &__date {
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 1.2px;
          }
        
          &__date {
            color: var(--theme-dark-gray);
          }
          
          &__text {
            margin: 0 0 0 70px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 1.2px;
          }
        }
        
        &__load-more {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 200px;
          margin: 35px auto 0 auto;
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          letter-spacing: 1.25px;
          text-transform: uppercase;
          color: var(--theme-dark-gray);
          transition: .3s all ease-in-out;
          
          &:hover {
            color: var(--theme-blue);
            cursor: pointer;
          
            .post {
              &__comments {
                &__answers {
                  &__load-more {
                    &__icon {
                      border-color: var(--theme-blue);
                      transform: rotate(1.25turn);
                      
                      path {
                        stroke: var(--theme-blue);
                      }
                    }
                  }
                }
              }
            }
          }
          
          &::before,
          &::after {
            content: '';
            position: absolute;
            top: 14px;
            width: 150px;
            height: 2px;
            background-color: var(--theme-gray);
          }
          
          &::before {
            left: -150px;
          }
          
          &::after {
            right: -150px;
          }
          
          &__icon {
            --dimensions: 30px;
            
            display: flex;
            align-items: center;
            justify-content: center;
            width: var(--dimensions);
            height: var(--dimensions);
            margin-left: 10px;
            border: 2px solid var(--theme-dark-gray);
            border-radius: 50%;
            transform: rotate(.25turn);
            transition: .3s all ease-in-out;
            
            path {
              transition: .3s all ease-in-out;
            }
          }
        }
      }
      
      &__success-msg {
        margin: 20px 0 0 0;
        border-radius: 8px;
        padding: 25px 50px;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        text-transform: uppercase;
        letter-spacing: 1.25px;
        color: var(--theme-dark-purple);
        box-shadow: 0 0 25px rgba(var(--black--rgb),0.2);
      }
      
      &__load-more {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        margin: 0 auto;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: var(--theme-dark-gray);
        transition: .3s all ease-in-out;
        
        &:hover {
          color: var(--theme-blue);
          cursor: pointer;
        
          .post {
            &__comments {
              &__load-more {
                &__icon {
                  border-color: var(--theme-blue);
                  transform: rotate(1.25turn);
                  
                  path {
                    stroke: var(--theme-blue);
                  }
                }
              }
            }
          }
        }
      
        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 14px;
          width: 200px;
          height: 2px;
          background-color: var(--theme-gray);
        }
        
        &::before {
          left: -200px;
        }
        
        &::after {
          right: -200px;
        }
        
        &__icon {
          --dimensions: 30px;
          
          display: flex;
          align-items: center;
          justify-content: center;
          width: var(--dimensions);
          height: var(--dimensions);
          margin-left: 10px;
          border: 2px solid var(--theme-dark-gray);
          border-radius: 50%;
          transform: rotate(.25turn);
          transition: .3s all ease-in-out;
          
          path {
            transition: .3s all ease-in-out;
          }
        }
      }
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/
  @media (max-width: 1280px) {
    .post {
      &__comments {
        &__opinion {
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }
  }
  
  @media (max-width: 1024px) {
    .post {
      &__comments {
        &__item {
          &__avatar {
            margin-right: 25px;
            
            &__icon {
              margin-right: 25px;
            }
          }
          
          &__text {
            padding-left: 105px;
          }
          
          &__reply {
            &__form {
              margin-left: 105px;
            }
          }
        }
        
        &__answers {
          margin-left: 105px;
        }
      }
    }
  }
  
  @media (max-width: 640px) {
    .post {
      &__comments {
        &__count {
          font-size: 26px;
          line-height: 28px;
        }
        
        &__opinion {
          &__text {
            font-size: 16px;
            line-height: 20px;
          }
          
          &__btn {
            width: 180px;
            height: 40px;
          }
        }
        
        &__form {
          padding: 20px;
          
          &__btn {
            width: 100%;
            height: 40px;
          }
        }
        
        &__item {
          &__avatar {
            --dimensions: 60px;
            
            margin-right: 15px;
            
            &__icon {
              width: 60px;
              height: 60px;
              margin-right: 15px;
            }
          }
          
          &__info {
            margin-top: 0;
          }
          
          &__text {
            padding-left: 0;
          }
          
          &__reply {
            &__form {
              margin-left: 25px;
              
              &__btn {
                width: 100%;
                height: 40px;
              }
            }
          }
        }
        
        &__list {
          padding: 20px;
        }
        
        &__answers {
          margin: 20px 0 10px 30px;
          
          &__item {
            &__text {
              margin-left: 0;
            }
          }
          
          &__load-more {
            &::before,
            &::after {
              width: 30px;
            }
            
            &::before {
              left: -30px;
            }
            
            &::after {
              right: -30px;
            }
          }
        }
        
        &__load-more {
          &::before,
          &::after {
            width: 50px;
          }
          
          &::before {
            left: -50px;
          }
          
          &::after {
            right: -50px;
          }
        }
      }
    }
  }
`;